import React from 'react';
import { Container, Typography } from '@mui/material';
import Plants from '../components/Plants';

const PlantsPage: React.FC = () => {
    return (
        <Container>
            <Typography variant="h3" sx={{ marginY: 3 }}>
                Plants Catalog
            </Typography>
            <Plants />
        </Container>
    );
};

export default PlantsPage;
