import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import api from '../api/api';
import { Box, Card, Typography } from '@mui/material';

interface Article {
    id: number;
    title: string;
}

const PlantArticles: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [articles, setArticles] = useState<Article[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await api.get(`/plants/${id}/articles`);
                setArticles(response.data);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticles();
    }, [id]);

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Articles about Plant {id}
            </Typography>
            <Box sx={{ display: 'grid', gap: 2 }}>
                {articles.map((article) => (
                    <Card
                        key={article.id}
                        sx={{ cursor: 'pointer', padding: 2 }}
                        onClick={() => navigate(`/articles/${article.id}`)}
                    >
                        <Typography variant="h6">{article.title}</Typography>
                    </Card>
                ))}
            </Box>
        </Box>
    );
};

export default PlantArticles;
