import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import useAuthStore from "../../store/authStore";

const Header: React.FC = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated());

    return (
        <header className="header">
            <nav className="nav">
                <div className="logo">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 21.4453 18.5254"
                        className="logo-icon">
                        <g>
                            <rect height="18.5254" opacity="0" width="21.4453" x="0" y="0"/>
                            <path
                                d="M0.283203 1.18164C0.078125 2.17773 0 3.47656 0 4.3457C0 11.6504 4.3457 16.4746 10.9863 16.4746C15.2637 16.4746 17.2754 13.9551 17.7148 13.1445L16.4941 13.1152C17.7637 14.4434 18.3301 15.8691 18.9746 17.8906C19.1211 18.3594 19.4434 18.5254 19.7852 18.5254C20.5078 18.5254 21.084 17.9004 21.084 17.041C21.084 15.6934 19.1504 13.3496 18.1445 12.4023C13.8672 8.45703 7.35352 10.791 5.67383 6.43555C5.54688 6.10352 5.89844 5.81055 6.2207 6.14258C9.58984 9.51172 13.9258 6.66992 18.1445 10.5566C18.4766 10.8496 18.8672 10.6934 18.9258 10.3418C18.9746 10.0586 19.0039 9.60938 19.0039 9.17969C19.0039 4.41406 15.6836 2.11914 11.0156 2.11914C9.45312 2.11914 7.62695 2.5 6.19141 2.5C4.61914 2.5 2.85156 2.37305 1.44531 0.869141C1.04492 0.458984 0.439453 0.517578 0.283203 1.18164Z"
                                fill="green"/>
                        </g>
                    </svg>
                    <span className="logo-text">Grow Green</span>
                </div>
                <div className="nav-buttons">
                    <Link to="/plants" className="nav-button">Plants</Link>
                    {isAuthenticated ? (
                        <Link to="/profile" className="nav-button">Profile</Link>
                    ) : (
                        <>
                            <Link to="/login" className="nav-button">Sign In</Link>
                            <Link to="/register" className="nav-button">Sign Up</Link>
                        </>
                    )}
                </div>
            </nav>
        </header>
    );
};

export default Header;
