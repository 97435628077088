import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api, { API_BASE_URL } from '../api/api';
import { Box, Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import { PlantModel } from "../models/PlantModel";
import { AddCircleOutline } from '@mui/icons-material';
import useAuthStore from "../store/authStore";
import {UserPlantModel} from "../models/UserPlantModel"; // Импорт хранилища авторизации

const Plants: React.FC = () => {
    const [plants, setPlants] = useState<PlantModel[]>([]);
    const [userPlants, setUserPlants] = useState<number[]>([]); // Состояние для хранения списка добавленных растений
    const navigate = useNavigate();
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated()); // Проверка авторизации

    useEffect(() => {
        // Функция для получения списка всех растений
        const fetchPlants = async () => {
            try {
                const response = await api.get('/plants');
                setPlants(response.data);
            } catch (error) {
                console.error('Error fetching plants:', error);
            }
        };

        // Функция для получения списка растений, добавленных пользователем
        const fetchUserPlants = async () => {
            if (isAuthenticated) {
                try {
                    const response = await api.get('/user-plants');
                    setUserPlants(response.data.map((plant: UserPlantModel) => plant.plant.id)); // Предполагается, что response.data - массив растений
                } catch (error) {
                    console.error('Error fetching user plants:', error);
                }
            }
        };

        fetchPlants();
        fetchUserPlants();
    }, [isAuthenticated]); // Запрашиваем список растений при изменении статуса авторизации

    const handlePlant = async (plantId: number) => {
        try {
            await api.post(`/user-plants/${plantId}`);
            alert('Plant successfully added!');
            setUserPlants((prev) => [...prev, plantId]); // Добавляем растение в локальное состояние
        } catch (error) {
            console.error('Error adding plant:', error);
            alert('Failed to add plant.');
        }
    };

    return (
        <Box sx={{ padding: 3, display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: 3 }}>
            {plants.map((plant) => (
                <Card
                    key={plant.id}
                    sx={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
                    onClick={() => navigate(`/plants/${plant.id}/articles`)}
                >
                    <CardMedia
                        component="img"
                        height="200"
                        image={`${API_BASE_URL}/files/${plant.imageName}` || 'https://via.placeholder.com/300'}
                        alt={plant.name}
                        sx={{
                            objectFit: 'contain', // Изображение сохраняет соотношение сторон и вписывается в заданные размеры
                            backgroundColor: '#f0f0f0', // Добавим фон для пустого пространства вокруг изображения
                        }}
                    />
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {plant.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                            {plant.description}
                        </Typography>
                        {isAuthenticated && !userPlants.includes(plant.id) && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation(); // Останавливает всплытие события, чтобы карточка не открывала статьи
                                    handlePlant(plant.id);
                                }}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '10px 20px',
                                    backgroundColor: '#388e3c',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    borderRadius: '20px',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#81c784',
                                        transform: 'scale(1.05)',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    },
                                    '&:focus': {
                                        outline: 'none',
                                        boxShadow: '0 0 0 2px rgba(56, 142, 60, 0.5)',
                                    },
                                }}
                            >
                                <AddCircleOutline sx={{ marginRight: 1 }} />
                                Plant
                            </Button>
                        )}
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
};

export default Plants;
