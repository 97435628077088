import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import Auth from './components/Auth/Auth';
import PlantsPage from './pages/PlantsPage';
import PlantArticles from "./components/PlantArticles";
import Article from "./components/Article";
import Profile from "./components/Profile";
import Header from './components/Header/Header';

const App: React.FC = () => {
    const location = useLocation();

    const shouldShowHeader = !['/login', '/register'].includes(location.pathname);

    return (
        <>
            {shouldShowHeader && <Header />}
            <Routes>
                <Route path="/" element={<Navigate to="/plants" replace />} />
                <Route path="/login" element={<Auth type="login"/>}/>
                <Route path="/register" element={<Auth type="register"/>}/>
                <Route path="/plants" element={<PlantsPage/>}/>
                <Route path="/plants/:id/articles" element={<PlantArticles/>}/>
                <Route path="/articles/:id" element={<Article/>}/>
                <Route path="/profile" element={<Profile/>}/>
            </Routes>
        </>
    );
};

const AppWithRouter: React.FC = () => (
    <Router>
        <App />
    </Router>
);

export default AppWithRouter;
