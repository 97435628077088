import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../api/api';
import { Box, Typography, CircularProgress } from '@mui/material';

interface Article {
    id: number;
    title: string;
    text: string;
}

const Article: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [article, setArticle] = useState<Article | null>(null);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await api.get(`/articles/${id}`);
                setArticle(response.data);
            } catch (error) {
                console.error('Error fetching article:', error);
            }
        };

        fetchArticle();
    }, [id]);

    if (!article) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ padding: 3, maxWidth: 800, margin: 'auto' }}>
            <Typography variant="h3" gutterBottom>
                {article.title}
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.8 }}>
                {article.text}
            </Typography>
        </Box>
    );
};

export default Article;
