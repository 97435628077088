import { AuthState, JwtPayload } from '../types';
import { create } from 'zustand/react';
import { jwtDecode } from 'jwt-decode';

const useAuthStore = create<AuthState>((set) => ({
    accessToken: localStorage.getItem('accessToken'),
    accessTokenExpiresAt: localStorage.getItem('accessTokenExpiresAt')
        ? new Date(localStorage.getItem('accessTokenExpiresAt')!)
        : null,
    setAccessToken: (token: string) => {
        set(() => {
            const decodedToken = jwtDecode<JwtPayload>(token);
            let expiresAt: Date | null = null;
            if (decodedToken.exp) {
                expiresAt = new Date(decodedToken.exp * 1000);
                localStorage.setItem('accessTokenExpiresAt', expiresAt.toISOString());
            }
            localStorage.setItem('accessToken', token);
            return {
                accessToken: token,
                accessTokenExpiresAt: expiresAt
            };
        });
    },
    setAccessTokenExpiresAt: (date: Date) => {
        set(() => {
            localStorage.setItem('accessTokenExpiresAt', date.toISOString());
            return { accessTokenExpiresAt: date };
        });
    },
    isAuthenticated: () => {
        const accessToken = localStorage.getItem('accessToken');
        const accessTokenExpiresAt = localStorage.getItem('accessTokenExpiresAt');

        if (!accessToken || !accessTokenExpiresAt) {
            return false;
        }

        const expiresAt = new Date(accessTokenExpiresAt);
        const now = new Date();

        now.setSeconds(now.getSeconds() + 30);
        return now < expiresAt;
    }
}));


export default useAuthStore;
