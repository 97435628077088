import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './Auth.css';
import api from "../../api/api";
import useAuthStore from "../../store/authStore";

interface AuthProps {
    type: 'login' | 'register';
}

const Auth: React.FC<AuthProps> = ({type}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const authStore = useAuthStore();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const endpoint = type === 'login' ? '/auth/login' : '/auth/register';
            const data = {
                username,
                password,
                ...(type === 'register' && {name}), // Добавляем имя только при регистрации
            };
            const response = await api.post(endpoint, data);
            if (type === 'login') {
                authStore.setAccessToken(response.data.accessToken);
                navigate('/plants');
            } else {
                alert('Registration successful!');
                navigate('/login'); // Перенаправление на страницу логина после регистрации
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error occurred!');
        }
    };

    const handleSwitch = () => {
        if (type === 'login') {
            navigate('/register');  // Navigate to the register page if in login mode
        } else {
            navigate('/login');  // Navigate to the login page if in register mode
        }
    };

    return (
        <div className="auth-container">
            <form onSubmit={handleSubmit} className="auth-form">
                <h2 className="auth-title">
                    {type === 'login' ? 'Login' : 'Register'}
                </h2>
                {type === 'register' && (
                    <input
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="auth-input"
                    />
                )}
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="auth-input"
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="auth-input"
                />
                <button type="submit" className="auth-button">
                    {type === 'login' ? 'Login' : 'Register'}
                </button>
                {type === 'login' && (
                    <button
                        type="button"
                        onClick={handleSwitch}
                        className="auth-switch-button"
                    >
                        Don't have an account? Register
                    </button>
                )}
                {type === 'register' && (
                    <button
                        type="button"
                        onClick={handleSwitch}
                        className="auth-switch-button"
                    >
                        Already have an account? Login
                    </button>
                )}
            </form>
        </div>
    );
};

export default Auth;
