import React, {useEffect, useState} from 'react';
import api, {API_BASE_URL} from '../api/api';
import {Box, Button, Card, CardContent, CardMedia, Divider, Typography} from '@mui/material';
import {UserPlantModel} from "../models/UserPlantModel";

interface User {
    id: number;
    name: string;
    email: string;
    plantedPlants: number;
}



const Profile: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [plants, setPlants] = useState<UserPlantModel[]>([]);

    useEffect(() => {
        // Получаем данные о пользователе
        const fetchUser = async () => {
            try {
                const response = await api.get('/users/me');
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        // Получаем список растений пользователя
        const fetchUserPlants = async () => {
            try {
                const response = await api.get('/user-plants');
                console.log(response.data)
                setPlants(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error fetching user plants:', error);
            }
        };

        void fetchUser();
        void fetchUserPlants();
    }, []);

    // Обработка полива растения
    const waterPlant = async (plantId: number) => {
        try {
            await api.post(`/waterings/${plantId}`);
            alert('Plant watered successfully!');
            // Обновляем список растений
            const response = await api.get('/user-plants');
            setPlants(response.data);
        } catch (error) {
            console.error('Error watering plant:', error);
            alert('Failed to water plant.');
        }
    };

    return (
        <Box sx={{ padding: 3 }}>
            {/* Информация о пользователе */}
            {user && (
                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Welcome, {user.name}!
                    </Typography>
                    <Typography variant="body1">
                        Total Planted Plants: {plants.length}
                    </Typography>
                </Box>
            )}

            {/* Список растений */}
            <Typography variant="h5" gutterBottom>
                Your Plants
            </Typography>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: 3 }}>
                {plants.map((plant) => (
                    <Card key={plant.id} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardMedia
                            component="img"
                            height="200"
                            image={`${API_BASE_URL}/files/${plant.plant.imageName}` || 'https://via.placeholder.com/300'}
                            alt={plant.name}
                        />
                        <CardContent>
                            <Typography variant="h6">{plant.plant.name}</Typography>
                            <Typography variant="body2" sx={{ marginY: 1 }}>
                                {plant.plant.description}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Waters: {plant.waterings?.length || 0}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ marginTop: 2 }}
                                onClick={() => waterPlant(plant.id)}
                            >
                                Water Plant
                            </Button>
                            <Divider sx={{ marginY: 2 }} />
                            <Typography variant="subtitle2">Watering Journal:</Typography>
                            {plant.waterings?.map((entry, index) => (
                                <Typography key={index} variant="body2" color="text.secondary">
                                    - {new Date(entry.wateredAt).toLocaleString()}
                                </Typography>
                            ))}
                        </CardContent>
                    </Card>
                ))}
            </Box>
        </Box>
    );
};

export default Profile;
